<template>
  <component :is="resolveLayout">
    <router-view></router-view>
    <!-- <upgrade-to-pro></upgrade-to-pro> -->
  </component>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import UpgradeToPro from './components/UpgradeToPro.vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
    UpgradeToPro,
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
  mounted() {
    // this.getClinicAddress()
  },
  methods: {
    getClinicAddress() {
      this.$store.dispatch('getClinicAddress').then(res => {
        console.log(res.length)
        if (res.length == 0) {
          if (this.$route.name != 'billing-form') {
            location.replace('/billing-form')
          }
        }
      })
    },
  },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai&display=swap');
#app {
  font-family: 'Noto Sans Thai', sans-serif;
}
</style>
