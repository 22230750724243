export const configcode = {
  debug: false, //enable console.log

  // ------------- Production Settings ----------------
  api: 'api',
  themeVersion: 3,
    //base saijai pos api url
    baseUrl: 'https://pos.saijai-crm.com',

    //base saijai api blob url
    basePublic: 'https://public.saijai-crm.com',
  
    //base saijai backoffice web url
    baseCrmUrl: 'https://backend.saijai-crm.com',
  
    //base saijai api blob url
    bzbsBlobUrl: 'https://buzzebees.blob.core.windows.net',
    themeVersion: 3,
    // ------------------ End Settings ---------------------
}
